.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: black;
  overflow: hidden;
}

.flipbook-container {
  width: 95vw;
  max-width: 600px;
  height: 50vh;
  max-height: 700px;
  margin: 0 auto;
  position: relative;
}
.flipbook {
  margin: auto;
}

.flipbook-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  /* object-fit: fill; */
}
